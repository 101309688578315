import { DashboardRouter } from "module/dashboard/DashboardRouter";
import { Suspense, useEffect, useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LandingRouter } from "module/landing/LandingRouter";
import { PlatformRouter } from "module/platform/PlatformRouter";
import { ProfileRouter } from "module/profile/ProfileRouter";
import Spinner from "module/common/component/display/Loading/Loading";
import { WalletRouter } from "module/wallet/WalletRouter";
import { useLoad } from "module/common/hooks/useLoad";
import { useRecoilValue } from "recoil";
import { AdminRole, authState } from "module/auth/AuthState";
import { AuthRouter } from "module/auth/AuthRouter";
import { ModalProvider } from "@peersyst/react-components";
import { UseLogout } from "module/auth/query/useLogout";
import IdleTimer from "./utils/idle-timer";

const Router = (): JSX.Element => {
    const isLoading = useLoad();
    const state = useRecoilValue(authState);

    useEffect(() => {
        if (!state.isLogged) {
            return;
        }

        const timer = new IdleTimer({
            timeout: 5 * 60, //expire after 5 minutes
            onTimeout: () => {
                const isDevelopment = process.env.NODE_ENV === "development";
                if (state.isLogged && !isDevelopment) UseLogout();
            },
            onExpired: () => {
                // do something if expired on load
            },
        });

        return () => {
            timer.cleanUp();
        };
    }, [state.isLogged]);

    const isAdmin = useMemo(() => {
        return Object.values(AdminRole).includes(state.role as AdminRole);
    }, [state.role]);

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ModalProvider>
                <Suspense fallback={<Spinner />}>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Routes>
                            {state.isLogged && ProfileRouter}
                            {state.isLogged && (isAdmin ? DashboardRouter : PlatformRouter)}
                            {state.isLogged && ProfileRouter}
                            {state.isLogged && WalletRouter}
                            {!state.isLogged && LandingRouter}
                            {AuthRouter}
                            <Route path="*" element={<Navigate to={!state.isLogged || isAdmin ? "" : "/owner"} />} />
                        </Routes>
                    )}
                </Suspense>
            </ModalProvider>
        </BrowserRouter>
    );
};

export default Router;
